/*=========================================================================================
  File Name: moduleEcommerceMutations.js
  Description: Ecommerce Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import _ from "lodash";

export default {
  TOGGLE_ITEM_IN_WISH_LIST(state, item) {
    const index = state.wishList.findIndex(i => i.objectID == item.objectID);
    if (index < 0) {
      state.wishList.push(item);
    } else {
      state.wishList.splice(index, 1);
    }
  },
  SET_CATEGORY_DATA(state, item) {
    state.categoryOptions = item;
  },

  REMOVE_ITEM_FROM_CART(state, item) {
    const index = state.cartItems.findIndex(
      i => i.productStoreId == item.productStoreId
    );
    if (index > -1) {
      state.cartItems.splice(index, 1);
    }
  },

  REMOVE_INACTIVE_ITEM_FROM_CART(state, item) {
    const index = state.inactiveCartItems.findIndex(
      i => i.productStoreId == item.productStoreId
    );
    if (index > -1) {
      state.inactiveCartItems.splice(index, 1);
    }
  },

  ADD_ITEM_IN_CART(state, item) {
    const index = state.cartItems.findIndex(
      i => i.productStoreId == item.productStoreId
    );
    if (index > -1) {
      state.cartItems[index].quantity = item.quantity;
    } else {
      state.cartItems.push(Object.assign({}, item));
    }
  },
  REMOVE_ALL_ITEMS_IN_CART(state) {
    state.cartItems = [];
  },
  API_CART_ITEMS(state, data) {
    state.cartItems = data.items.filter(item => !item.product.hideStatus || item.product.ifHiddenThenVisibleToClinics.includes(data.clinicId));
    state.inactiveCartItems = data.items.filter(item => item.product.hideStatus && !item.product.ifHiddenThenVisibleToClinics.includes(data.clinicId));
  },
  UPDATE_ITEM_QUANTITY(state, { data }) {
    const index = _.findIndex(state.cartItems, { _id: data._id });
    state.cartItems[index].quantity = data.quantity;
  },
  UPDATE_SEARCH_TEXT( state, value){
    state.searchText = value
  },
  TOGGLE_SHOW_SEARCH_MODAL(state, value){
    state.showSearchModal = value
  },
  REMOVE_ITEM_FROM_CART_TEST(state, item) {
    const index = state.cartItems.findIndex(
      (i) => i.productStoreId == item._id
    );
    if (index > -1) {
      state.cartItems.splice(index, 1);
    }
  },
  UPDATE_ITEM_QUANTITY_TEST(state, data) {
    const index = _.findIndex(state.cartItems, {
      productStoreId: data.data._id,
    });
    state.cartItems[index].quantity = data.qty;
  },
  UPDATE_SHOW_ALERT_HEADER(state, value){
    state.showAlertHeader = value
  },
};
