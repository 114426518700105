/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";
import { assign } from "lodash";

export default {
  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then((response) => {
          // If there's user data in response
          if (response.data) {
            // console.log(router.currentRoute.query)
            // Navigate User to homepage
            router.push("/admin/dashboard");
            // router.push(router.currentRoute.query.to || '/')
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.user)
            );
            localStorage.setItem("firstName", response.data.data.firstName);
            // localStorage.setItem('permissions', JSON.stringify(response.data.data.permit));
            // localStorage.setItem('token', JSON.stringify(user.data.token));
            // localStorage.setItem('AccessToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,user.data.token.accessToken);
            // localStorage.setItem('refreshToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,response.data.token.refreshToken);
            localStorage.setItem(
              "refreshToken",
              response.data.data.token.refreshToken
            );
            // console.log(new Date(new Date().setTime() + 60 * 60 * 24 * 1000))
            var date = new Date().getTime() + 60 * 60 * 24 * 1000;
            localStorage.setItem("tokenExpiry", date);
            localStorage.setItem("loggedIn", "true");
            // Set accessToken
            localStorage.setItem(
              "accessToken",
              response.data.data.token.accessToken
            );

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data, { root: true });
            // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})
            // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            resolve(response);
          } else {
            reject({ message: response.data.msg });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.msg });
          // reject(error)
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .registerClinic(payload.userDetails)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || "/");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkEmail({ commit }, email) {
    return new Promise((resolve, reject) => {
      jwt
        .checkEmailAvailability(email)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDoctors({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchDoctorUrl =
        "/api/v1/doctors/userManagement/doctors?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        encodeURIComponent(data.search) +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&userType=" +
        data.userType +
        "&filter=" +
        data.filter +
        "&activeClinicId=" +
        data.activeClinicId;
      if (data.franchiseId) {
        fetchDoctorUrl += "&franchiseId=" + data.franchiseId;
      }
      axios
        .get(fetchDoctorUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchClinicEMRDoctors({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchDoctorUrl =
        "/api/v1/doctors/userManagement/emr-doctors?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&userType=" +
        data.userType +
        "&filter=" +
        data.filter +
        "&clinicId=" +
        data.clinicId;
      axios
        .get(fetchDoctorUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAssignEmrClinics({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/emr-doctor/clinics/assign/list", { params: {id: id} })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchDoctorEMRClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchDoctorUrl =
        "/api/v1/doctors/emr-doctor/"+data.doctorId+"/clinics/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter;
      axios
        .get(fetchDoctorUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchSuburbs({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/getSuburbs", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAuthorizingDoctors({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/authorizingDoctors")
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDoctorDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/userManagement/doctors/" + id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  updateDoctorDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/doctors/userManagement/doctors/" + data.id, data.data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  deleteDoctor({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/doctors/userManagement/doctor/" + id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  removeAssignedClinic({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/doctors/directorAssignedClinic`, { params: { assignedClinicId: data } })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        })
    })
  },
  resetDoctorPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/doctors/userManagement/doctors/" +
          data.id +
          "/resetPassword",
          data.data
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  createDoctor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/doctors/userManagement/doctors/create", data.userDetails)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchPatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/patients", { params: data })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchDoctorTreatmentHistory({ commit }, data) {
    if (!data.datatableOptions.dir) {
      data.datatableOptions.dir = "desc";
      data.datatableOptions.sort = "appointmentDate";
    }

    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/userManagement/doctors/" +
          data.doctorId +
          "/treatment-history",
          { params: data.datatableOptions }
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchDoctorRoleTreatmentHistory({ commit }, data) {
    if (!data.datatableOptions.dir) {
      data.datatableOptions.dir = "desc";
      data.datatableOptions.sort = "appointmentDate";
    }

    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/userManagement/doctors/role/" +
          data.doctorId +
          "/treatment-history",
          { params: data.datatableOptions }
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchDoctorRoleOnCallTreatmentHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/role/treatment/log",
          { params: data }
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchDoctorOnCallTreatmentHistory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/treatment/log",
          { params: data }
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  exportCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/doctors/userManagement/doctors/export-csv?page=" + data.page +
          "&limit=" + data.limit +
          "&exportMode=" + data.exportMode +
          "&clinicId=" + data.clinicId +
          "&isDoctorEmr=" + data.isDoctorEmr
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  assignEmrDoctor({ commit },data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/doctors/userManagement/emr-doctors/assign/${data.clinicId}`,{"userIds":data.userId})
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  removeEmrDoctor({ commit },data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/doctors/userManagement/remove/emr-doctor/${data.clinicId}`,{"userId":data.userId})
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchUnassignedEMRDoctors({ commit }, clinicId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/emr-doctors"+'?clinicId='+clinicId)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getDoctorDashboardData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/doctors/dashbaordData`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  respondClinicJoinRequest({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/doctors/acceptRejectClinicJoinRequest`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTreatmentHistory({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/doctors/getAllTreatmentHistories`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signAndSend({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/doctors/signAndSend`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendMonthlyReportToAdmin({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/doctors/sendMonthlyReport`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signAndSendWeek({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/doctors/signAndSend`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchNurseDoctors({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/userManagement/nurseDoctors", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveDoctorBankDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/doctors/bankDetails/create", data)
        .then((res) => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchTreatmentOutcomesList({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/treatmentOutcome/history/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateDoctorBankDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/doctors/bankDetails/edit", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPostPhotoHistoryList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/treatmentPhoto/history/" +
          data.id +
          "?value=" +
          data.isBeforePhoto
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateDoctorRoles({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/doctors/toggleDoctorRoles", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateAssignedClinicMedicalDoctor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/doctors/assignDirectorsClinic", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  fetchSupervisedUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/supervisedUsers/?doctorId=" +
          data.doctorId
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchAssignedMedicalDoctor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/doctors/directorAssignedClinic/?doctorId=" + data.doctorId
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }
};
