import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  fetchNurses({ commit }, data) {
    return new Promise((resolve, reject) => {
      let fetchNursesUrl =
        "/api/v1/nurses/nurse-list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        encodeURIComponent(data.search) +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter +
        "&activeClinicId=" +
        data.activeClinicId;
      if (data.franchiseId && data.franchiseId !== "") {
        fetchNursesUrl += "&franchiseId=" + data.franchiseId;
      }
      axios
        .get(fetchNursesUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchNurseDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/nurses/nurse/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateNurseDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/nurses/nurse/" + data.id, data.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchNurseTreatmentHistory({ commit }, info) {
    let data = info.datatableOptions;
    let id = info.nurseId;
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/nurses/nurse/treatment-history/" + id, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/nurses/nurse/export-csv?page=" + data.page +
          "&limit=" + data.limit +
          "&exportMode=" + data.exportMode +
          "&clinicId=" + data.clinicId
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getTreatmentDetails({ commit }, patientCheckinId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/nurses/nurse/treatment-detail/" + patientCheckinId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateNotes({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/nurses/nurse/update-note`, {
          data: params,
        })
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  deleteAppointmentPhoto(
    { commit },
    { imageUrl, appointmentHistoryId, appointmentId, photoId }
  ) {
    return new Promise((resolve, reject) => {
      let config = {
        params: {
          appointmentHistoryId: appointmentHistoryId,
          appointmentId: appointmentId,
          imageUrl: imageUrl,
          photoId: photoId,
        },
      };
      axios
        .post(`/api/v1/nurses/nurse/delete-appointment-photo`, {}, config)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  uploadAppointmentPhoto({ commit }, { event, isBeforePhoto, appointmentId }) {
    return new Promise((resolve, reject) => {
      let data = new FormData();
      data.append("appointmentImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          isBeforePhoto: isBeforePhoto,
          appointmentId: appointmentId,
        },
      };
      axios
        .post(`/api/v1/nurses/nurse/upload-appointment-photo`, data, config)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  getBodyParts({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/nurses/get-body-parts`)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  getNurseDashboardData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/nurses/dashbaordData`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteNurse({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/nurses/userManagement/nurse/" + id)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  resetNursePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/nurses/userManagement/nurse/" + data.id + "/resetPassword",
          data.data
        )
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/notifications/getCmsNotifications`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllTreatmentHistory({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/nurses/getAllTreatmentHistories`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createNurseBasic({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/nurses/nurse/onboard-basic`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
